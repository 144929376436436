/* eslint-disable class-methods-use-this */
import { apiService } from './api';

class TestFormService {

    fetchSubmissionToken(notificationId) {
        //console.log('inside fetchSubmissionToken');
    return apiService({
      method: 'get',
      url: `TestNotificationSet('${notificationId}')`,
      headers: { 'X-CSRF-Token': 'Fetch' }
    });
  }
    
    //indexOfSpecialChar(string, startIndex = 0) {
    //    const specialChars = '/~!@#$%^&*()_-+=|\'<>';
    //    for (let i = 0; i < specialChars.length; i++) {
    //        const specialCharIndex = string.indexOf(specialChars[i], startIndex);
    //        if (specialCharIndex > -1) return specialCharIndex;
    //    }
    //    return -1;
    //}

    submitForm(notificationId, data) {

        // convert data to utf8 string
        const utf8Str = unescape(encodeURIComponent(JSON.stringify(data)));
        // convert utf8Str to base64 string
        const dataStr = btoa(utf8Str);


        //console.log('dataStr', dataStr);

        return apiService({
            method: 'put',
            url: `TestFormSet('${notificationId}')`,
            data: { payload: dataStr }
    });
  }

  removeAssembly(notificationId, data) {
    const removingData = data;
    removingData.TestType = 'RM';

    return apiService({
      method: 'put',
      url: `TestFormSet('${notificationId}')`,
      data: removingData,
    });
  }
}

export const testFormService = new TestFormService();
